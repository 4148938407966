export function percentsNumber(number, places = undefined) {
  if (number === undefined || number === null) return number;

  number = Number(number) * 100;
  if (places === undefined) {
    const absNumber = Math.abs(number);
    places = 0;
    if (0 < absNumber && absNumber < 1) {
      places = 2;
    } else if (1 <= absNumber && absNumber < 10) {
      places = 1;
    }
  }

  return abbreviateNumber(number, places, places);
}

export function extractNumberSign(value) {
  return value > 0 ? "+" : value < 0 ? "-" : "";
}

export function abbreviateNumber(
  number,
  maxPlaces,
  forcePlaces = 2,
  forceLetter = false
) {
  number = Number(number);
  forceLetter = forceLetter || false;
  if (forceLetter !== false) {
    return annotateNumber(number, maxPlaces, forcePlaces, forceLetter);
  }
  var abbr;
  if (Math.abs(number) >= 1e12) {
    abbr = "T";
  } else if (Math.abs(number) >= 1e9) {
    abbr = "B";
  } else if (Math.abs(number) >= 1e6) {
    abbr = "M";
  } else if (Math.abs(number) >= 1e3) {
    abbr = "K";
  } else {
    abbr = "";
  }
  return annotateNumber(number, maxPlaces, forcePlaces, abbr);
}

function annotateNumber(number, maxPlaces, forcePlaces, abbr) {
  // set places to false to not round
  var rounded = 0;
  switch (abbr) {
    case "T":
      rounded = number / 1e12;
      break;
    case "B":
      rounded = number / 1e9;
      break;
    case "M":
      rounded = number / 1e6;
      break;
    case "K":
      rounded = number / 1e3;
      break;
    case "":
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    var test = new RegExp("\\.\\d{" + (maxPlaces + 1) + ",}$");
    if (test.test("" + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces);
  }
  return rounded + abbr;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setCustomerNameInString(value, customerName) {
  customerName = customerName || "mb"; // if customerName is undefined, set it to "mb"
  if (customerName == "mb") customerName = "Your"; // if customerName is "mb", should replace with "Your" in string

  return value !== undefined && customerName !== undefined
    ? value.replace("{customer}", customerName)
    : value;
}

export function setFrequencyInString(value, frequency) {
  const frequencyOptions = { 0: "Monthly", 1: "Weekly", 2: "Daily" };

  frequency =
    frequency && 0 <= frequency <= 2
      ? frequencyOptions[frequency]
      : frequencyOptions[0];

  return value !== undefined ? value.replace("{frequency}", frequency) : value;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getPercent(part, total, roundTo = 2) {
  return parseFloat((part / total) * 100).toFixed(roundTo) + "%";
}

export function isFunction(obj) {
  return typeof obj === "function";
}

export function removeEmptyLines(input) {
  return input
    .split(/\r?\n/) // Split input text into an array of lines
    .filter((line) => line.trim() !== "") // Filter out lines that are empty or contain only whitespace
    .join("\n"); // Join line array into a string
}

export function extractTextFromElement(elem) {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(extractTextFromElement).join("");
  }
  return extractTextFromElement(children);
}

export function arrayToChunks(array, chunkSize = 10) {
  let res = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

export function objectsEqual(o1, o2) {
  if (o2 === null && o1 !== null) return false;
  return o1 !== null && typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 !== null &&
      Array.isArray(o1) &&
      Array.isArray(o2) &&
      !o1.length &&
      !o2.length
    ? true
    : o1 === o2;
}

export function objectToArray(originalObject, valueKey) {
  const transformedArray = [];

  for (const key in originalObject) {
    for (const value in originalObject[key]) {
      let valueEntry = transformedArray.find(
        (entry) => entry[valueKey] === value
      );
      if (!valueEntry) {
        valueEntry = { [valueKey]: value };
        transformedArray.push(valueEntry);
      }
      valueEntry[key] = originalObject[key][value];
    }
  }
  return transformedArray;
}

export function getCurrencySignByDomain(domain) {
  switch (domain) {
    case "emart.ssg.com":
      return "₩";
    case "gocart.ph":
      return "₱";
    case "amazon.in":
    case "flipkart.com":
      return "₹";
    case "amazon.nl":
      return "€";
    default:
      return "$";
  }
}
