import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import DataTable from "../../../../components/data-table/data-table";

import styles from "../insights-skus.scss";
import { CategoryLeaf } from "../../../../utils/filters/filtersUtils";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
let smallLoader = {
  loaderWidth: LoaderWidths.Small,
};

const columnsConfig = [
  {
    id: 2,
    label: "Sku",
    accessor: "skuData",
    tooltip: "",
    cellDecorator: Decorators.skuTrackerSkuDecorator,
    loaderWidth: LoaderWidths.Huge,
  },
  {
    ...smallLoader,
    id: 7,
    label: "Median Retail Price",
    accessor: "price",
    tooltip: "The Median Retail Price during the selected time period.",
    cellDecorator: Decorators.priceDecoratorWithCurrency,
  },
  {
    ...smallLoader,
    id: 8,
    label: "Retail Price Diff",
    accessor: "priceDiff",
    tooltip:
      "Percent change in Retail Price between the current time period and the previous period",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 10,
    label: "Days OOS",
    accessor: "outOfStockDays",
    tooltip:
      "Number of days the product is out of stock for the competing retailer (Max 31 days)",
    cellDecorator: Decorators.defaultNumericDecorator("days_oos"),
  },
  {
    ...smallLoader,
    id: 11,
    label: "Average Rating",
    accessor: "averageRating",
    tooltip: "Average user rating for the product on the product page",
    cellDecorator: Decorators.defaultNumericDecorator("avg_rating", 1),
  },
  {
    ...smallLoader,
    id: 34,
    label: "Average Rating Diff",
    accessor: "averageRatingDiff",
    cellDecorator: Decorators.numberChangeDecorator,
  },
  {
    ...smallLoader,
    id: 35,
    label: "Number Of Ratings",
    accessor: "numberOfRatings",
    tooltip: "The total number of ratings for a given SKU",
    cellDecorator: Decorators.numberWithCommasDecorator,
  },
  {
    ...smallLoader,
    id: 12,
    label: "Number Of Ratings Diff",
    accessor: "ratingCountDiff",
    tooltip:
      "The difference between the number of ratings for this SKU in the selected frequency and the previous period",
    cellDecorator: Decorators.roundNumberChangeDecorator,
  },
  {
    ...smallLoader,
    id: 13,
    label: "Map",
    accessor: "map",
    tooltip:
      "Minimum advertised price - taken from the customer vendor portal to compare with the retail price in the different websites. A MAP of 0 or higher indicates that the product belongs to your company.",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    ...smallLoader,
    id: 14,
    label: "Map Diff",
    accessor: "mapDiff",
    tooltip:
      "The Difference between the MAP (Minimum Advertised Price) and the Retail Price",
    cellDecorator: Decorators.numberChangeDecorator,
  },
  {
    ...smallLoader,
    id: 16,
    label: "Last Seen",
    accessor: "lastSeen",
    tooltip: "The last time we have seen this product online.",
    cellDecorator: Decorators.daysDecorator("last_seen"),
  },
  {
    ...smallLoader,
    id: 18,
    label: "Wholesale Price",
    accessor: "wholesalePrice",
    tooltip:
      "The Average Wholesale Price for the given time period (only your company's products).",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    ...smallLoader,
    id: 19,
    label: "Wholesale Price Diff",
    accessor: "wholesalePriceDiff",
    tooltip:
      "Percent change in wholesale price between the current time period and the previous period (only your company's products).",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 20,
    label: "Wholesale Revenue",
    accessor: "wholesaleRevenue",
    tooltip:
      "The wholesale revenue that your company makes by the selling the product to the retailer for the given time period. (only your company's products)",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    ...smallLoader,
    id: 21,
    label: "Wholesale Revenue Diff",
    accessor: "wholesaleRevenueDiff",
    tooltip:
      "Percent change in wholesale revenue between the current time period and the previous period. (only your company's products)",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 22,
    label: "Retail Sales Dollars",
    accessor: "salesDollars",
    tooltip:
      "The sales $ that the retailer makes by selling your company's product for the selected time period.",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    ...smallLoader,
    id: 23,
    label: "Retail Sales Dollars Diff",
    accessor: "salesDollarsDiff",
    tooltip:
      "Percent change in retail sales dollars between the current time period and the previous period.",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 24,
    label: "Retail Margin",
    accessor: "margin",
    tooltip:
      "The percentage of total sales that the retailer can consider profit from selling your company's product",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    ...smallLoader,
    id: 25,
    label: "Retail Margin Diff",
    accessor: "marginDiff",
    tooltip:
      "Percent change in retail margin between the current time period and the previous period.",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 26,
    label: "Unit Sold",
    accessor: "sales",
    tooltip: "Number of units sold for the selected time period",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 27,
    label: "Unit Sold Diff",
    accessor: "salesDiff",
    tooltip:
      "Percent units sold change between the current time period and the previous period",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 28,
    label: "Sponsored Rate",
    accessor: "sponsoredRate",
    tooltip:
      "For when an item is visible (See Visibility Period), the percentage of time that a product has the sponsored tag on a retailer website.",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    ...smallLoader,
    id: 29,
    label: "Sponsored Rate Diff",
    accessor: "sponsoredRateDiff",
    tooltip:
      "Percent change in sponsored rate between the current time period and the previous period.",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 30,
    label: "Rank",
    accessor: "rank",
    tooltip:
      "For when an item is visible (See Visibility Period), the median page position of a product in the first 3 pages of its relevant category and selected time period. A rank of 1 means the product is on average the first item seen.",
    cellDecorator: Decorators.rankDecorator,
  },
  {
    ...smallLoader,
    id: 31,
    label: "Rank Diff",
    accessor: "rankDiff",
    tooltip:
      "The absolute change in median rank of a product in comparison to the previous period.",
    cellDecorator: Decorators.roundNumberChangeDecorator,
  },
  {
    ...smallLoader,
    id: 32,
    label: "Visibility Period",
    accessor: "visibilityPeriod",
    tooltip:
      "The percent of time that an item is visibly ranked in the top results in first 3 pages (when searching using the keywords defined for that category).",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    ...smallLoader,
    id: 33,
    label: "Visibility Period Diff",
    accessor: "visibilityPeriodDiff",
    tooltip:
      "The percent change in how visible a product was from the previous period to the current period for the selected time period.",
    cellDecorator: Decorators.percentChangeDecorator,
  },
  {
    ...smallLoader,
    id: 117,
    label: "Last Collected Price",
    accessor: "lastCollectedPrice",
    tooltip: "The most recently collected price",
    cellDecorator: Decorators.priceDecoratorWithCurrency,
  },
  {
    ...smallLoader,
    id: 118,
    label: "Promotion Days",
    accessor: "promoDays",
    tooltip: "Promotion duration: Number of days for promotion",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 119,
    label: "Previous Visibility Period",
    accessor: "prevVisibilityPeriod",
    tooltip:
      "The percent of time that an item is visibly () ranked in the top results in first 3 pages (when searching using the keywords defined for that category) for the previous period.",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    ...smallLoader,
    id: 120,
    label: "Previous Sponsored Rate",
    accessor: "prevSponsoredRate",
    tooltip:
      "For when an item is visible (See Visibility Period), the percentage of time that a product has the sponsored tag on a retailer website for the previous period.",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    ...smallLoader,
    id: 121,
    label: "Region",
    accessor: "region",
    cellDecorator: Decorators.basicDecorator,
  },
  {
    ...smallLoader,
    id: 122,
    label: "State",
    accessor: "state",
    cellDecorator: Decorators.basicDecorator,
  },
  {
    ...smallLoader,
    id: 123,
    label: "Store",
    accessor: "store",
    cellDecorator: Decorators.basicDecorator,
  },
  {
    ...smallLoader,
    id: 124,
    label: "Available Units",
    accessor: "available_units",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 125,
    label: "Image Count",
    accessor: "imageCount",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 126,
    label: "Video Count",
    accessor: "videoCount",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 127,
    label: "Document Count",
    accessor: "documentCount",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 128,
    label: "360 View Count",
    accessor: "panoramaCount",
    cellDecorator: Decorators.intDecorator,
  },
  {
    ...smallLoader,
    id: 129,
    label: "A+",
    accessor: "isAPlus",
    cellDecorator: Decorators.yesNoDecorator,
    nullAsNo: true,
  },
  {
    ...smallLoader,
    id: 130,
    label: "Enhanced A+",
    accessor: "aPlusPremium",
    cellDecorator: Decorators.yesNoDecorator,
    nullAsNo: true,
  },
  {
    ...smallLoader,
    id: 131,
    label: "Comparison Table",
    accessor: "aPlusComparison",
    cellDecorator: Decorators.yesNoDecorator,
    nullAsNo: true,
  },
  {
    ...smallLoader,
    id: 132,
    label: "Videos in A+",
    accessor: "aPlusVideo",
    cellDecorator: Decorators.yesNoDecorator,
    nullAsNo: true,
  },
  {
    ...smallLoader,
    id: 133,
    label: "QA",
    accessor: "aPlusFaq",
    cellDecorator: Decorators.yesNoDecorator,
    nullAsNo: true,
  },
];

const disableTooltipForColumns = [];
var sortableColumns = [
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  117,
  118,
  124,
  125,
  126,
  127,
  128,
  129,
  130,
  131,
  132,
  133,
];

function prepareDataForTable(data) {
  const { productList, frequency: frequencyId } = data;
  const frequencyMap = ["30d", "7d", "1d", "2cw", "4cw"];
  const frequency = frequencyMap[frequencyId];

  const priceFrequencyMap = ["30d", "7d", "", "2cw", "4cw"];
  const priceFrequency = priceFrequencyMap[frequencyId];

  return productList.map((item) => {
    return {
      imageUrl: item.image,
      title: item.name,
      retailer: item.rootDomain,
      category: item.mbCategory,
      brand: item.brand,
      seller: item.topSeller,
      price: {
        price: item["price" + priceFrequency],
        rootdomain: item.rootDomain,
      },
      priceDiff: item["priceDiffPct" + frequency],
      promoDays:
        frequency === "1d" ? item["isPromo"] : item["promoDays" + frequency],
      numberOfRatings: item.numberOfRatings,
      visibilityPeriods: item["visibilityPeriods" + frequency],
      averageRating: item.averageRating,
      averageRatingDiff: item["averageRatingDiff" + frequency],
      ratingCountDiff: item["ratingCountDiff" + frequency],
      outOfStockDays: item.outOfStockDays,
      upc: item.upc,
      map: item.map,
      itemNumber: item.itemNumber,
      storeSku: item.storeSku,
      mapDiff: item.mapDiff,
      wholesalePrice: item["wholesalePrice" + frequency],
      wholesalePriceDiff: item["wholesalePriceDiff" + frequency],
      wholesaleRevenue: item["wholesaleRevenue" + frequency],
      wholesaleRevenueDiff: item["wholesaleRevenueDiff" + frequency],
      salesDollars: item["salesDollars" + frequency],
      salesDollarsDiff: item["salesDollarsDiff" + frequency],
      margin: item["margin" + frequency],
      marginDiff: item["marginDiff" + frequency],
      sales: item["sales" + frequency],
      salesDiff: item["salesDiff" + frequency],
      sponsoredRate: item["sponsoredRatio" + frequency],
      sponsoredRateDiff: item["sponsoredRatioDiff" + frequency],
      rank: item["rankPos" + frequency],
      rankDiff: item["rankPosDiff" + frequency],
      visibilityPeriod: item["visibilityPeriod" + frequency],
      visibilityPeriodDiff: item["visibilityPeriodDiff" + frequency],
      prevVisibilityPeriod: item["prevVisibilityPeriod" + frequency],
      prevSponsoredRate: item["prevSponsoredRatio" + frequency],
      lastSeen: item.lastSeen,
      mpn: item.mpn,
      lastCollectedPrice: { price: item.price, rootdomain: item.rootDomain },
      region: item.region,
      state: item.state,
      store: item.store,
      available_units: item.availableUnits,
      imageCount: item.imageCount,
      videoCount: item.videoCount,
      documentCount: item.documentCount,
      panoramaCount: item.panoramaCount,
      isAPlus: item.isAPlus,
      aPlusPremium: item.aPlusPremium,
      aPlusComparison: item.aPlusComparison,
      aPlusVideo: item.aPlusVideo,
      aPlusFaq: item.aPlusFaq,
      skuData: {
        name: item.name,
        image: item.image,
        sku: item.sku,
        brand: item.brand,
        retailer: item.rootDomain,
        topSeller: item.topSeller,
        upc: item.upc,
        mpn: item.mpn,
        itemNumber: item.itemNumber,
        storeSku: item.storeSku,
        productType: CategoryLeaf(item.mbCategory),
      },
    };
  });
}

function TopSkusTable(props) {
  const data = {
    productList: props.productList,
    frequency: props.frequency,
  };

  return (
    <>
      <DataTable
        header="SKU Tracker"
        columnsConfig={columnsConfig}
        onSaveSelectedColumns={props.onSaveSelectedColumns}
        excludedTableColumns={props.excludedTableColumns}
        sorting={props.sorting}
        selectedColumns={props.selectedColumns}
        sortableColumns={sortableColumns}
        disableTooltipForColumns={disableTooltipForColumns}
        prepareSortingValues={props.prepareSortingValues}
        prepareDataForTable={prepareDataForTable}
        onSelectRow={props.onSelectRow}
        styles={styles}
        data={data}
        isLoading={props.isLoading}
        isSticky={true}
      />
    </>
  );
}

export default TopSkusTable;
